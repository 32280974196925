.float-container {
  border: 3px solid #fff;
}

.float-child {
  width: 50%;
  float: left;
}

.line-item {
  line-height: 2.5em;
  font-size: 1.2rem;
}
