.card-header {
  font-weight: bold;
  text-align: center;
}

.legal-terms {
  font-size: smaller;
  text-align: center;
}

li {
  line-height: 3em;
  font-size: 1.2rem;
  text-align: center;
}

.link {
  text-decoration: none;
}
.link:hover {
  text-decoration: none;
}

a {
  color: black !important;
}
a:hover {
  color: black;
}
