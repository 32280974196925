.wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding-left: 10rem;
  padding-right: 10rem;
}

.center {
  text-align: center;
}
