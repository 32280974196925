.card-title {
  font-weight: bold;
  font-size: 1rem !important;
}

.card-price {
  font-weight: bold;
  font-size: 1.25rem !important;
}

.card-img {
  width: 5% !important;
}

.card-img {
  width: 5% !important;
}

.card-title {
  font-weight: bold;
}

.column {
  width: 50%;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.card-body {
  width: 80% !important;
  padding: 0.5rem !important;
  text-align: center;
}

.card-button {
  width: 20% !important;
  padding: 0.5rem !important;
  text-align: center;
  vertical-align: middle;
  max-width: 5em !important;
}

.card-description {
  margin-top: 0.25rem !important;
  margin-bottom: 0 !important;
  font-size: 0.9rem !important;
}

.image {
  width: 100%;
}
