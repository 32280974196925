.accordion-body {
  padding: 0.5rem 1rem !important;
  font-size: medium !important;
}

.green {
  background-color: green;
}

.none {
  background-color: none;
}
