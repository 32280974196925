#invoice-POS {
  padding: 0mm;
  width: 58mm;
  background: #fff;
  left: 0;
  top: 0;
  margin-bottom: 0 !important;
}
#invoice-POS ::selection {
  background: #f31544;
  color: #fff;
}
#invoice-POS ::moz-selection {
  background: #f31544;
  color: #fff;
}
#invoice-POS h1 {
  font-size: 2em;
  margin-bottom: 0 !important;
}
#invoice-POS h2 {
  font-size: 1em;
  margin-bottom: 0 !important;
}
#invoice-POS p {
  font-size: 0.9em;
  color: black;
}
#invoice-POS .contact-info {
  text-align: center;
  font-size: 0.5em;
  color: black;
}
#invoice-POS #top,
#invoice-POS #mid,
#invoice-POS #bot {
  /* Targets all id with 'col-' */
  border-bottom: 1px solid #eee;
}
#invoice-POS .info {
  display: block;
  margin-left: 0;
}
#invoice-POS .title {
  float: right;
}
#invoice-POS .title p {
  text-align: right;
}
#invoice-POS table {
  width: 100%;
  border-collapse: collapse;
}
#invoice-POS .number {
  text-align: right;
  font-size: 0.9em;
  vertical-align: top;
}
#invoice-POS .orderDate {
  text-align: center;
  font-size: 0.85em;
}
#invoice-POS .orderReservedDate {
  text-align: center;
  font-size: 1.4em;
}
#invoice-POS .tabletitle {
  font-size: 0.8em;
  background: #eee;
}
#invoice-POS .service {
  border-bottom: 1px solid #eee;
}
#invoice-POS .description {
  width: 43mm;
}
#invoice-POS .unit {
  text-align: center;
  font-size: 1em;
  width: 2mm;
  vertical-align: top;
}
#invoice-POS .subtotal {
  text-align: right;
  width: 8mm;
}
#invoice-POS .itemtext {
  font-size: 0.9em;
}

#invoice-POS .orderNumber {
  font-size: 2.5em;
  text-align: center;
}

#invoice-POS .Rate {
  text-align: right;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
