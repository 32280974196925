.td {
  margin: 0;
}

.ticket-button {
  margin: 2rem !important;
}

.text-error {
  background-color: lightyellow !important;
  vertical-align: middle;
}

.text-simple {
  vertical-align: middle;
}

.text-done {
  background-color: lightgreen !important;
  vertical-align: middle;
}
