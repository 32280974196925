.incomeFilters {
  display: grid;
  padding-left: 2rem;
  padding-right: 2rem;
}

.incomeResults {
  display: grid;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: right;
}
