.dialog_background {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

.dialog {
  align-items: center;
  background: white;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  min-height: 0.5rem !important;
  padding: 20px;
  position: absolute;
  top: 7%;
  width: 100%;
  max-width: 100%;
  max-height: 95vh !important;
  overflow-y: scroll;
}

.menu_button {
  text-align: center;
  margin-top: 0.5rem;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
}
