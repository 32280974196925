.navbar_style {
  background-color: #060b26;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem;
  align-items: center;
}

.menu-bars {
  margin-left: 1.4rem;
  font-size: 1.5rem;
  background: none;
}

.menu-bars-text {
  color: #f5f5f5;
  font-size: 12px;
  align-items: center;
}

.logo {
  position: absolute;
  top: 0%;
  left: 0%;
  margin: -26px 0px 0px 10px;
  clip: rect(0px, 150px, 76px, 0px);
}
