/* HIDE RADIO */
[type="radio"] {
  width: 1.2rem;
  height: 1.2rem;
}

/* IMAGE STYLES */
[type="radio"] + img {
  cursor: pointer;
}

.radio {
  margin-left: 1rem;
  margin-right: 1rem;
}

label {
  width: 100%;
}

.checkoutWrapper {
  display: grid;
  grid-template-columns: 6fr 5fr;
}

.checkoutSubtotalAlign {
  text-align: right !important;
}
